@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .btn-blue {
    @apply py-2 px-4 bg-white text-indigo-900 font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .basic-card {
    @apply p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4;
  }
}